import React, { useEffect, useState } from 'react';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { useApiCall } from '../../hooks/useAPICall';
import { FaEye } from 'react-icons/fa';
import { GrFormPrevious } from 'react-icons/gr';
import { GrFormNext } from 'react-icons/gr';
import { dateGenerator } from '../../util/CommonFunc';
import { toast } from 'sonner';
import ViewCompanyData from './ViewCompanyData';

function CompanyTable() {
  const [data, setData] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [qty, setQty] = useState(25);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    if (modal?.id) {
      document.getElementById('my_modal_1').showModal();
    }
  }, [modal]);

  const coloumnDef = [
    { header: 'id', accessorKey: 'id' },
    { header: 'Name', accessorKey: 'name' },
    { header: 'Phone', accessorKey: 'contactNo' },
    { header: 'Email', accessorKey: 'email' },
    { header: 'CIN', accessorKey: 'uniqueId' },
    {
      header: 'Enrollment Date',
      accessorKey: 'enrolledDate',
      cell: info => {
        const value = info.cell.getValue();
        return <p>{dateGenerator(value)}</p>;
      },
    },
    {
      header: 'Expiry Date',
      accessorKey: 'expireDate',
      cell: info => {
        const value = info.cell.getValue();
        return <p>{dateGenerator(value)}</p>;
      },
    },
    {
      header: 'Validity',
      accessorKey: 'validity',
    },
    // {
    //   header: 'Active',
    //   accessorKey: 'isActive',
    //   cell: info => {
    //     const value = info.cell.getValue();
    //     return (
    //       <p
    //         className={`${
    //           value === 'Active' ? 'text-green-500' : 'text-red-500'
    //         }`}
    //       >
    //         {value}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   header: 'Payment Status',
    //   accessorKey: 'paymentStatus',
    //   cell: info => {
    //     const value = info.cell.getValue();
    //     return (
    //       <p
    //         className={`${
    //           value === 'Paid' ? 'text-green-500' : 'text-red-500'
    //         }`}
    //       >
    //         {value}
    //       </p>
    //     );
    //   },
    // },
    {
      header: 'Aproval',
      accessorKey: 'adminApproval',
      cell: info => {
        const value = info.cell.getValue();
        return (
          <p
            className={`${value === 'YES' ? 'text-green-500' : 'text-red-500'}`}
          >
            {value || 'NO'}
          </p>
        );
      },
    },
    {
      header: 'Action',
      cell: info => {
        // console.log(info.row, '***');
        return (
          <div className="flex justify-between px-1">
            <FaEye
              className="cursor-pointer"
              onClick={() => {
                setModal(info.row.original);
              }}
            />
            <dialog id="my_modal_1" className="modal">
              {modal?.id && (
                <ViewCompanyData data={modal} setModal={setModal} />
              )}
            </dialog>
          </div>
        );
      },
    },
  ];
  const table = useReactTable({
    data: data,
    columns: coloumnDef,
    getCoreRowModel: getCoreRowModel(),
  });

  const { adminGetApiCall, isLoading, result, isError } = useApiCall();

  useEffect(() => {
    if (!modal) {
      adminGetApiCall(
        `/api/admin/getAllCompanies?pageNo=${pageNo}&pageSize=${qty}`
      );
    }
  }, [pageNo, qty, modal]);

  useEffect(() => {
    if (Array.isArray(result)) {
      setData(result);
    }
  }, [result]);

  useEffect(() => {
    if (isError) {
      toast.error(`Something went wrong with error code ${isError.status}`);
    }
  }, [isError]);

  return (
    <div className="w-full p-2">
      <div className="h-[calc(100vh-150px)] overflow-y-auto">
        {isLoading ? (
          'Loading...'
        ) : (
          <table className="w-full text-sm border table-auto border-slate-500">
            <thead className="border-b border-slate-500">
              {table.getHeaderGroups().map((headerData, index) => (
                <tr key={index}>
                  {headerData.headers.map((header, index) => (
                    <th key={index} className="resize">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={index}
                  className="dark:even:bg-slate-800 even:bg-slate-200"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td key={index} className="px-1 border-l border-slate-500">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-2 pt-2">
        <div className="flex gap-2">
          <label htmlFor="qty">Rows per page:</label>
          <select
            name="qty"
            id="qty"
            onChange={e => {
              setQty(parseInt(e.target.value));
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        <div className="flex items-center justify-center w-full gap-2">
          <div
            className={`flex items-center justify-center duration-150 ${
              pageNo === 0
                ? 'cursor-not-allowed'
                : 'cursor-pointer hover:text-blue-500'
            } `}
            onClick={() => {
              pageNo > 0 && setPageNo(prev => prev - 1);
            }}
          >
            <GrFormPrevious className="text-2xl" />
            <p>Prev</p>
          </div>

          <p>{pageNo + 1}</p>
          <div
            className={`flex items-center justify-center duration-150  ${
              data.length === qty
                ? 'hover:text-blue-500 cursor-pointer'
                : 'cursor-not-allowed'
            } `}
            onClick={() => {
              data.length === qty && setPageNo(prev => prev + 1);
            }}
          >
            <p>Next</p>
            <GrFormNext className="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyTable;
