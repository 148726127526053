import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { toast } from 'sonner';
import { useTheme } from '../hooks/useTheme';
import useLogout from './useLougout';

export function useAuthentication() {
  const [authenticated, setAuthenticated] = useState(false);
  const [detecting, setDetecting] = useState(true);

  const { logout } = useLogout();

  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');

  const { applyTheme } = useTheme();
  useEffect(() => {
    setDetecting(true);

    applyTheme();

    if (token) {
      try {
        const jwtData = jwtDecode(token);
        // console.log(jwtData, '***jwt');
        const userInfoArr = jwtData.sub.split(',');
        // console.log(userInfoArr, '****');
        if (username === userInfoArr[1]) {
          localStorage.setItem('role', userInfoArr[2]);
          setDetecting(false);
          setAuthenticated(true);
        } else {
          toast.error('Unauthorized! Access denied.');
          setDetecting(false);
          logout();
          setAuthenticated(false);
        }
      } catch (error) {
        // console.log(error, '***');
        toast.error('Unauthorized! Access denied.');
        setDetecting(false);
        logout();
        setAuthenticated(false);
      }
    } else {
      logout();
      setDetecting(false);
      setAuthenticated(false);
    }
  }, []);

  return { authenticated, detecting, username };
}
