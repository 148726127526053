import React from 'react';
import { Link } from 'react-router-dom';

function Card({ data }) {
  return (
    <div className="flex flex-col justify-between w-full p-8 duration-300 border rounded-md shadow-md bg-yellow-50 dark:bg-slate-800 dark:shadow-blue-900/40 hover:shadow-2xl h-[280px]">
      <div className="flex justify-between w-full text-cyan-700 dark:text-cyan-400">
        <p className="text-3xl">{data.label}</p>
        <p className="text-[60px]">{data.icon}</p>
      </div>
      <div className="flex items-center justify-center">
        <div className="rounded-full   w-[100px] h-[100px] flex justify-center items-center border border-cyan-500 dark:border-blue-700">
          <p className="text-3xl text-cyan-700 dark:text-cyan-400">
            {data.count}
          </p>
        </div>
      </div>
      <div className="flex justify-end w-full">
        <Link to={data.link}>
          <p className="p-2 text-xl text-blue-500 duration-300 border border-blue-500 rounded-md cursor-pointer hover:bg-blue-600 dark:hover:bg-blue-800 hover:text-white dark:hover:text-slate-200 hover:shadow-lg hover:shadow-blue-500/50 dark:hover:shadow-blue-500">
            See All
          </p>
        </Link>
      </div>
    </div>
  );
}

export default Card;
