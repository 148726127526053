import React, { useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import { crowdFundingSchema } from '../../schemas/schemas';
import InputField from '../common/FormFields/InputField';
import { useApiCall } from '../../hooks/useAPICall';
import { toast } from 'sonner';

const initialValues = {
  title: '',
  description: '',
  minimumContribution: '',
  targetAmount: '',
};

function AddCrowdFunding({ setRowData, setAdd }) {
  const {
    adminPostApiCall,
    isLoading,
    result,
    success,
    isError,
    reset,
    adminGetApiCall,
  } = useApiCall();

  const resetButtonRef = useRef(null);

  const triggerResetButtonClick = () => {
    if (resetButtonRef.current) {
      resetButtonRef.current.click();
    }
  };

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: crowdFundingSchema,
    onSubmit: values => {
      adminPostApiCall({
        url: '/api/admin/createNewCrowdFunding',
        body: values,
      });
    },
  });

  useEffect(() => {
    if (success) {
      toast.success('Crowd funding created');
      triggerResetButtonClick();
      reset();
      setAdd(false);
      setRowData();
    }
  }, [result, success]);

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          ref={resetButtonRef}
          onClick={() => {
            resetForm();
            setAdd(false);
          }}
        >
          ✕
        </button>
      </form>
      <form className="w-full" onSubmit={handleSubmit}>
        <InputField
          name={'title'}
          label={'Title'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.title && touched.title ? errors.title : false}
          value={values.title}
          type={undefined}
          placeholder={undefined}
        />
        <InputField
          name={'description'}
          label={'Description'}
          onChange={handleChange}
          onBlur={handleBlur}
          error={
            errors.description && touched.description
              ? errors.description
              : false
          }
          value={values.description}
          type={undefined}
          placeholder={undefined}
        />
        <div className="flex gap-2">
          <InputField
            name={'minimumContribution'}
            label={'Minimum Contribution'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.minimumContribution && touched.minimumContribution
                ? errors.minimumContribution
                : false
            }
            value={values.minimumContribution}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'targetAmount'}
            label={'Target Amount'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.targetAmount && touched.targetAmount
                ? errors.targetAmount
                : false
            }
            value={values.targetAmount}
            type={undefined}
            placeholder={undefined}
          />
        </div>
        <div className="flex justify-center w-full">
          <button className="w-32 text-lg btn btn-primary" type="submit">
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddCrowdFunding;
