import React from 'react';

import { OperatorForm } from './OperatorForm';

function Operator() {
  return (
    <div className="flex justify-end w-full px-2 py-4">
      <button
        className="btn btn-primary"
        onClick={() => document.getElementById('my_modal_2').showModal()}
      >
        Add Operator
      </button>
      <dialog id="my_modal_2" className="modal">
        <OperatorForm />
      </dialog>
    </div>
  );
}

export default Operator;
