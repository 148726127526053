import React, { useEffect } from 'react';
import { dateGenerator } from '../../util/CommonFunc';
import { useApiCall } from '../../hooks/useAPICall';
import { toast } from 'sonner';

function ViewCompanyData({ data, setModal }) {
  const { adminPostApiCall, isLoading, result, isError } = useApiCall();
  function approveHandler() {
    adminPostApiCall({
      url: `/api/admin/approveCompany?id=${data.id}&req=YES`,
      body: {},
    });
  }
  function rejectHandler() {
    adminPostApiCall({
      url: `/api/admin/approveCompany?id=${data.id}&req=NO`,
      body: {},
    });
  }

  useEffect(() => {
    if (typeof result === 'string' && result.includes('approved')) {
      const id = result.match(/ID (\d+) has/);
      toast.success(`Approval / rejection of ID: ${id[1]} Successful`);
      setModal(false);
    }
  }, [result]);

  const tempData = [
    { label: 'Id', value: data.id },
    { label: 'Name', value: data.name },
    { label: 'DOB', value: dateGenerator(data.dateOfBirth) },
    { label: 'Phone', value: data.contactNo },
    { label: 'Email', value: data.email },
    { label: 'CIN', value: data.uniqueId },
    { label: 'Enrollment Date', value: dateGenerator(data.enrolledDate) },
    { label: 'Expiry Date', value: dateGenerator(data.expireDate) },
    { label: 'isActive', value: data.isActive },
    { label: 'Approval', value: data.adminApproval },
    { label: 'Payment', value: data.paymentStatus },
    { label: 'House No', value: data.houseNo },
    { label: 'Street', value: data.streetAddress },
    { label: 'Area', value: data.area },
    { label: 'City', value: data.city },
    { label: 'State', value: data.state },
    { label: 'Pin Code', value: data.pincode },
  ];

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => {
            setModal(false);
          }}
        >
          ✕
        </button>
      </form>

      <div className="grid grid-cols-3 gap-2">
        {tempData.map(item => (
          <div key={item.label}>
            {item.label} : {item.value}
          </div>
        ))}
      </div>

      <div className="flex justify-end w-full gap-2 py-2">
        <button
          className="btn btn-primary"
          onClick={() => {
            approveHandler();
          }}
          disabled={data.adminApproval === 'YES' || isLoading}
        >
          {isLoading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            'Approve'
          )}
        </button>
        <button
          className="btn btn-error"
          onClick={() => {
            rejectHandler();
          }}
          disabled={data.adminApproval !== 'YES' || isLoading}
        >
          {isLoading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            'Reject'
          )}
        </button>
      </div>
    </div>
  );
}

export default ViewCompanyData;
