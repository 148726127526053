import React, { useEffect, useState } from 'react';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { IoMenu } from 'react-icons/io5';
import { MdOutlineDarkMode } from 'react-icons/md';
import { MdOutlineWbSunny } from 'react-icons/md';
import { useTheme } from '../../hooks/useTheme';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineCorporateFare } from 'react-icons/md';
import { IoPeopleOutline } from 'react-icons/io5';
import { MdOutlineDashboard } from 'react-icons/md';
import { FaRegUser } from 'react-icons/fa';
import { IoMdLogOut } from 'react-icons/io';
import useLogout from '../../auth/useLougout';
import { RxCross2 } from 'react-icons/rx';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { BsChatRightQuote } from 'react-icons/bs';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { MdOutlineHandyman } from 'react-icons/md';

function Navbar() {
  const [darkMode, setDarkMode] = useState(localStorage.getItem('theme'));
  const [label, setLabel] = useState('');
  const [viewMenu, setViewMenu] = useState(false);
  const { changeTheme } = useTheme();
  let location = useLocation();
  const { logout } = useLogout();

  useEffect(() => {
    const urlArr = location.pathname.split('/');
    setLabel(urlArr[urlArr.length - 1]);
  }, [location.pathname]);

  const ref = useOutsideClick(() => {
    if (viewMenu) {
      setViewMenu(false);
    }
  });

  const handleThemeChange = () => {
    setDarkMode(prev => (prev === 'dark' ? 'light' : 'dark'));
    changeTheme();
  };

  useEffect(() => {
    if (viewMenu) {
      document.body.classList.add('remove-scrolling');
    } else {
      document.body.classList.remove('remove-scrolling');
    }
  }, [viewMenu]);

  const styleMenu = `flex items-center gap-2 px-2 py-2 text-lg rounded dark:hover:bg-slate-900 hover:bg-slate-200 duration-300`;

  return (
    <div className="bg-gray-100 border-b shadow-md select-none dark:border-gray-600 dark:bg-gray-700 dark:shadow-gray-700 navbar">
      <div className="relative navbar-start">
        <div
          className="flex items-center justify-center w-12 h-12 text-2xl duration-300 cursor-pointer btn-ghost btn-circle"
          onClick={() => {
            setViewMenu(prev => !prev);
          }}
        >
          {viewMenu ? <RxCross2 /> : <IoMenu />}
        </div>

        {
          <div
            className={`w-[250px] h-[calc(100vh-67px)] absolute top-[57px] ${
              viewMenu ? 'left-[-8px]' : 'left-[-270px]'
            } bg-[#F3F4F6] dark:bg-[#374151] p-4 flex flex-col gap-2 transition-all duration-300`}
            ref={ref}
          >
            <Link to="/dashboard" className={styleMenu}>
              <MdOutlineDashboard /> Dashboard
            </Link>

            <Link to="/dashboard/operators" className={styleMenu}>
              <IoPeopleOutline /> Operators
            </Link>

            <Link to="/dashboard/company" className={styleMenu}>
              <MdOutlineCorporateFare /> Company
            </Link>

            <Link to="/dashboard/complaint" className={styleMenu}>
              <BsChatRightQuote /> Complaint
            </Link>

            <Link to="/dashboard/crowd-funding" className={styleMenu}>
              <FaMoneyBillAlt /> Crowd Funding
            </Link>

            <Link to="/dashboard/job" className={styleMenu}>
              <MdOutlineHandyman /> Job
            </Link>
          </div>
        }

        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost btn-circle">
            <FaRegUser className="text-2xl" />
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-fit"
          >
            <li>
              <div
                className="flex"
                onClick={() => {
                  logout();
                }}
              >
                <IoMdLogOut /> Logout
              </div>
            </li>
          </ul>
        </div>

        <div
          className="p-2 text-2xl btn btn-ghost btn-circle"
          onClick={handleThemeChange}
        >
          {darkMode === 'dark' ? <MdOutlineDarkMode /> : <MdOutlineWbSunny />}
        </div>

        {/* <button className="btn btn-ghost btn-circle">
          <div className="indicator">
            <IoIosNotificationsOutline className="text-2xl" />
            <span className="badge badge-xs badge-primary indicator-item ">
              4
            </span>
          </div>
        </button> */}
      </div>

      <div className="navbar-center">
        <p className="text-2xl capitalize text-primary">{label}</p>
      </div>

      <div className="navbar-end">
        <img
          src="/assets/logo.png"
          alt="site-logo-dashboard"
          className="w-12 h-12"
        />
      </div>
    </div>
  );
}

export default Navbar;
