import React, { useEffect, useState } from 'react';
import { useApiCall } from '../../hooks/useAPICall';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { FaEye } from 'react-icons/fa';
import ViewCrowdFundingData from './ViewCrowdFundingData';
import { GrFormPrevious } from 'react-icons/gr';
import { GrFormNext } from 'react-icons/gr';
import AddCrowdFunding from './AddCrowdFunding';

function CrowdFundingTable() {
  const { adminGetApiCall, isLoading, result, reset } = useApiCall();

  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState();
  const [add, setAdd] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [qty, setQty] = useState(25);

  useEffect(() => {
    if (rowData?.id) {
      document.getElementById('my_modal_1')?.showModal();
    } else {
      adminGetApiCall(
        `/api/operator/getAllCrowdFunding?pageNo=${pageNo}&pageSize=${qty}`
      );
    }
  }, [rowData, pageNo, qty]);

  useEffect(() => {
    if (add) {
      document.getElementById('my_modal_2').showModal();
    } else {
      adminGetApiCall(
        `/api/operator/getAllCrowdFunding?pageNo=${pageNo}&pageSize=${qty}`
      );
    }
  }, [add]);

  const coloumnDef = [
    { header: 'id', accessorKey: 'id' },
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Description',
      accessorKey: 'description',
      cell: info => {
        const value = info.cell.getValue();
        return (
          <div className="tooltip tooltip-right" data-tip={value}>
            <p className="max-w-[40vw] truncate">{value}</p>
          </div>
        );
      },
    },
    { header: 'Min Contribution', accessorKey: 'minimumContribution' },
    { header: 'Target Amount', accessorKey: 'targetAmount' },
    { header: 'Amount Raised', accessorKey: 'amountRaised' },
    { header: 'Status', accessorKey: 'status' },
    {
      header: 'Action',
      cell: info => {
        return (
          <div className="flex justify-between px-1">
            <FaEye
              className="cursor-pointer"
              onClick={() => {
                setRowData(info.row.original);
              }}
            />
            <dialog id="my_modal_1" className="modal">
              {rowData?.id && (
                <ViewCrowdFundingData
                  data={rowData}
                  setRowData={setRowData}
                  pageNo={pageNo}
                  qty={qty}
                />
              )}
            </dialog>
          </div>
        );
      },
    },
  ];

  const table = useReactTable({
    data: data,
    columns: coloumnDef,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(result)) {
      setData(result);
    }
  }, [result]);

  return (
    <div className="w-full p-2">
      <div className="flex justify-end w-full px-2 py-4">
        <button
          className="btn btn-primary"
          onClick={() => {
            setAdd(true);
          }}
        >
          Add Crowd Funding
        </button>
        {add && (
          <dialog id="my_modal_2" className="modal">
            <AddCrowdFunding setRowData={setRowData} setAdd={setAdd} />
          </dialog>
        )}
      </div>
      <div className="h-[calc(100vh-230px)] overflow-y-auto">
        {isLoading ? (
          'Loading...'
        ) : (
          <table className="w-full text-sm border table-auto border-slate-500">
            <thead className="border-b border-slate-500">
              {table.getHeaderGroups().map((headerData, index) => (
                <tr key={index}>
                  {headerData.headers.map((header, index) => (
                    <th key={index} className="resize">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={index}
                  className="dark:even:bg-slate-800 even:bg-slate-200"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td key={index} className="px-1 border-l border-slate-500">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <div className="flex flex-col items-center justify-center w-full gap-2 pt-2">
        <div className="flex gap-2">
          <label htmlFor="qty">Rows per page:</label>
          <select
            name="qty"
            id="qty"
            onChange={e => {
              setQty(parseInt(e.target.value));
            }}
          >
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
            <option value="1000">1000</option>
          </select>
        </div>
        <div className="flex items-center justify-center w-full gap-2">
          <div
            className={`flex items-center justify-center duration-150 ${
              pageNo === 0
                ? 'cursor-not-allowed'
                : 'cursor-pointer hover:text-blue-500'
            } `}
            onClick={() => {
              pageNo > 0 && setPageNo(prev => prev - 1);
            }}
          >
            <GrFormPrevious className="text-2xl" />
            <p>Prev</p>
          </div>

          <p>{pageNo + 1}</p>
          <div
            className={`flex items-center justify-center duration-150  ${
              data.length === qty
                ? 'hover:text-blue-500 cursor-pointer'
                : 'cursor-not-allowed'
            } `}
            onClick={() => {
              data.length === qty && setPageNo(prev => prev + 1);
            }}
          >
            <p>Next</p>
            <GrFormNext className="text-2xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CrowdFundingTable;
