import React from 'react';
import { dateGenerator } from '../../util/CommonFunc';

function ViewJobData({ data, setRowData }) {
  console.log(data, '***d');

  const tempData = [
    { label: 'Id', value: data.id },
    { label: 'Title', value: data.title },
    { label: 'Description', value: data.description },
    { label: 'Comapny Name', value: data.companyName },
    { label: 'Date Of Posting', value: dateGenerator(data.dateOfPosting) },
    { label: 'Status', value: data.status },
    { label: 'Area', value: data.area },
    { label: 'Type', value: data.type },
    { label: 'Openings', value: data.numberOfOpenings },
    { label: 'Experience', value: data.yearsOfExperience },
    { label: 'Salary', value: data.salary },
    { label: 'Duty Hours', value: data.dutyHours },
    { label: 'Job Duration Months', value: data.jobDurationMonths },
    { label: 'Provident', value: data.providentFundIncluded ? 'Yes' : 'No' },
    { label: 'Work Shift', value: data.workingShift },
    { label: 'Food Accomodation', value: data.foodAccomodation ? 'Yes' : 'No' },
    { label: 'Travel Allowance', value: data.travelAllowance ? 'Yes' : 'No' },
    { label: 'Contact Number', value: data.contactNumber },
    { label: 'Work Type', value: data.workType },
  ];

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => {
            setRowData();
          }}
        >
          ✕
        </button>
      </form>
      <div className="grid grid-cols-2 gap-2">
        {tempData.map(item => (
          <div key={item.label}>
            {item.label} : {item.value}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ViewJobData;
