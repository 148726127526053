import React from 'react';
import Navbar from '../components/common/Navbar';
import ComplaintTable from '../components/complaints/ComplaintTable';

function Complaint() {
  return (
    <div>
      <Navbar />
      <ComplaintTable />
    </div>
  );
}

export default Complaint;
