import * as Yup from 'yup';

export const operatorSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  father: Yup.string().required("Father's Name is required"),
  designation: Yup.string().required('Designation is required'),
  contactNo: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits')
    .required('Contact Number is required'),
  uniqueId: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(12, 'Must be exactly 12 digits')
    .max(12, 'Must be exactly 12 digits')
    .required('Adhaar number is required'),
  houseNo: Yup.string().required('House No. is required'),
  street: Yup.string().required('Street is required'),
  area: Yup.string().required('Area is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  pinCode: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, 'Must be exactly 6 digits')
    .max(6, 'Must be exactly 6 digits')
    .required('Pin Code is required'),
  dob: Yup.string().required('Date of Birth is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

export const crowdFundingSchema = Yup.object().shape({
  title: Yup.string().required('Title is required').min(10).max(500),
  description: Yup.string()
    .required('Description is required')
    .min(50, 'Description must be at least 50 characters long')
    .max(5000, 'Description must be at least 50 characters long'),
  minimumContribution: Yup.number()
    .required('Minimum Contribution is required')
    .min(10, 'Minimum Contribution must be at least 10'),
  targetAmount: Yup.number()
    .required('Target Amount is required')
    .min(100, 'Target Amount must be at least 100'),
});

export const jobSchema = Yup.object({
  companyId: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  desciption: Yup.string().required('Required'),
  dateOfPosting: Yup.date().required('Required'),
  status: Yup.string().required('Required'),
  area: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  noOfOpenings: Yup.number().required('Required').min(1, 'Must be at least 1'),
  yearsOfExperience: Yup.number()
    .required('Required')
    .min(0, 'Cannot be negative'),
  salary: Yup.number().required('Required').min(0, 'Cannot be negative'),
  dutyHours: Yup.number().required('Required').min(0, 'Cannot be negative'),
  jobDurationMonths: Yup.number()
    .required('Required')
    .min(1, 'Must be at least 1'),
  providentFundIncluded: Yup.boolean().required('Required'),
  workingShift: Yup.string().required('Required'),
  foodAccomodation: Yup.boolean().required('Required'),
  travelAllowance: Yup.boolean().required('Required'),
  contactNumber: Yup.string()
    .required('Required')
    .matches(/^\d{10}$/, 'Must be a valid phone number'),
  workType: Yup.string().required('Required'),
});
