import React from 'react';
import Navbar from '../components/common/Navbar';
import CompanyTable from '../components/company/CompanyTable';

function CompanyPage() {
  return (
    <div>
      <Navbar />
      <CompanyTable />
    </div>
  );
}

export default CompanyPage;
