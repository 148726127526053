import React from 'react';
import Navbar from '../components/common/Navbar';
import DashboardPage from '../components/landingPage/DashboardPage';

function DashBoardPage() {
  return (
    <div className="">
      <Navbar />
      <DashboardPage />
    </div>
  );
}

export default DashBoardPage;
