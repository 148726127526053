export const dateGenerator = time => {
  const date = new Date(time);
  let options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Asia/Kolkata',
  };
  let indian_date = date.toLocaleDateString('en-IN', options);
  return indian_date;
};
