import React from 'react';
import Navbar from '../components/common/Navbar';
import Operator from '../components/oporator/Operator';
import OperatorTable from '../components/oporator/OperatorTable';

function OperatorPage() {
  return (
    <div>
      <Navbar />
      <Operator />
      <OperatorTable />
    </div>
  );
}

export default OperatorPage;
