import React, { useEffect, useState } from 'react';
import { useApiCall } from '../../hooks/useAPICall';
import Card from './Card';
import { BsChatRightQuote } from 'react-icons/bs';
import { FaMoneyBillAlt } from 'react-icons/fa';
import { MdOutlineHandyman } from 'react-icons/md';
import { MdOutlineCorporateFare } from 'react-icons/md';
import { IoPeopleOutline } from 'react-icons/io5';

function DashboardPage() {
  const [data, setData] = useState();
  const { adminGetApiCall, result, success, isLoading } = useApiCall();
  useEffect(() => {
    adminGetApiCall('/api/admin/getDashboardReferenceData');
  }, []);

  const dataCard = [
    {
      label: 'Operators',
      icon: <IoPeopleOutline />,
      link: '/dashboard/operators',
    },
    {
      label: 'Comapnies',
      icon: <MdOutlineCorporateFare />,
      link: '/dashboard/company',
    },
    {
      label: 'Jobs',
      icon: <MdOutlineHandyman />,
      link: '/dashboard/job',
    },
    {
      label: 'Complaints',
      icon: <BsChatRightQuote />,
      link: '/dashboard/complaint',
    },
    {
      label: 'Crowd Fundings',
      icon: <FaMoneyBillAlt />,
      link: '/dashboard/crowd-funding',
    },
  ];

  useEffect(() => {
    if (success) {
      const keys = Object.keys(result);
      const temp = keys.map((item, index) => {
        return { ...dataCard[index], count: result[item] };
      });
      setData(temp);
    }
  }, [result, success]);

  console.log(data, '***d');

  return (
    <div className="grid grid-cols-3 gap-6 p-4">
      {data && data.map(item => <Card data={item} key={item.label} />)}
    </div>
  );
}

export default DashboardPage;
