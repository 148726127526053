import React from 'react';
import Navbar from '../components/common/Navbar';
import CrowdFundingTable from '../components/CrowdFunding/CrowdFundingTable';

function CrowdFunding() {
  return (
    <div>
      <Navbar />

      <CrowdFundingTable />
    </div>
  );
}

export default CrowdFunding;
