import React, { useState } from 'react';
import { useEffect } from 'react';
import { useTheme } from '../../hooks/useTheme';
import { FaUser } from 'react-icons/fa';
import { FaKey } from 'react-icons/fa';
import { useFormik } from 'formik';
import { loginSchema } from '../../schemas/loginSchema';
import { useApiCall } from '../../hooks/useAPICall';
import { FaEyeSlash } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa';

const initialvalues = {
  username: '',
  password: '',
};

function LoginForm() {
  const [passVisible, setPassVisivle] = useState(false);
  const { applyTheme } = useTheme();
  const { loginApiCall, isLoading, result } = useApiCall();
  useEffect(() => {
    applyTheme();
  }, []);

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialvalues,
    validationSchema: loginSchema,
    onSubmit: values => {
      loginApiCall('/auth/signin', {
        username: values.username,
        password: values.password,
      });
    },
  });

  if (result?.success) {
    resetForm();
  }

  return (
    <div className="flex items-center justify-center w-full h-screen">
      <div className="flex flex-col items-center justify-center gap-5 w-[360px] border dark:border-gray-600 dark:bg-slate-800 h-fit shadow-xl p-4 rounded-lg">
        <img
          src="/assets/logo.png"
          alt="site-logo"
          className="w-20 h-20 mb-2"
        />
        <p className="text-xl">Welcome Admin</p>
        <form
          className="flex flex-col items-center justify-center w-full"
          onSubmit={handleSubmit}
        >
          <label className="flex items-center w-full gap-2 input input-bordered input-primary">
            <FaUser className="text-primary" />
            <input
              type="text"
              className="grow"
              placeholder="Username"
              id="username"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
            />
          </label>

          {errors.username && touched.username ? (
            <p className="text-sm text-error py-0.5"> {errors.username}</p>
          ) : (
            <p className="text-sm text-error py-0.5">&nbsp;</p>
          )}

          <label className="flex items-center w-full input input-bordered input-primary">
            <div className="flex items-center w-full gap-2">
              <FaKey className="text-primary" />
              <input
                type={passVisible ? 'text' : 'password'}
                className="grow"
                placeholder="Password"
                id="password"
                name="password"
                disabled={isLoading}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </div>
            <div
              className="flex items-center justify-center text-xl cursor-pointer w-fit h-fit"
              onClick={e => {
                e.stopPropagation();
                setPassVisivle(prev => !prev);
              }}
            >
              {passVisible ? <FaEyeSlash /> : <FaEye />}
            </div>
          </label>

          {errors.password && touched.password ? (
            <p className="text-sm text-error py-0.5"> {errors.password} </p>
          ) : (
            <p className="text-sm text-error py-0.5">&nbsp;</p>
          )}

          <button className="w-32 text-lg btn btn-primary" type="submit">
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              'Login'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
