import './App.css';

import { Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import DashBoardPage from './pages/DashBoardPage';
import ProtectedRoutes from './auth/ProtectedRoutes';
import { useTheme } from './hooks/useTheme';
import { useEffect } from 'react';
import OperatorPage from './pages/OperatorPage';
import CompanyPage from './pages/CompanyPage';
import { Toaster } from 'sonner';
import Complaint from './pages/Complaint';
import CrowdFunding from './pages/CrowdFunding';
import JobPage from './pages/JobPage';

function App() {
  const { detectWidndowsTheme } = useTheme();

  useEffect(() => {
    const theme = localStorage.getItem('theme');
    if (!theme) detectWidndowsTheme();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/dashboard" element={<DashBoardPage />} />
          <Route path="/dashboard/operators" element={<OperatorPage />} />
          <Route path="/dashboard/company" element={<CompanyPage />} />
          <Route path="/dashboard/complaint" element={<Complaint />} />
          <Route path="/dashboard/crowd-funding" element={<CrowdFunding />} />
          <Route path="/dashboard/job" element={<JobPage />} />
        </Route>
      </Routes>
      <Toaster
        position="bottom-right"
        expand={true}
        duration={6000}
        richColors
        closeButton
      />
    </>
  );
}

export default App;
