import { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';

export const useApiCall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [result, setResult] = useState({});
  const [success, setSuccess] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  const loginApiCall = async (url, body) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${apiBaseUrl}${url}`, body);
      setIsLoading(false);
      if (res.status === 200) {
        localStorage.setItem('token', res.data?.accessToken);
        localStorage.setItem('username', res.data?.username);
        setResult({ success: true });
        toast.success('Login successful.');
        navigate('/dashboard');
      }
    } catch (error) {
      setIsLoading(false);
      //   console.log(error.response, '***e');
      setResult({ success: false });
      if (error?.response?.status === 401) {
        toast.error('Invalid username or password.');
      } else {
        toast.error('Something went wrong try again later.');
      }
    }
  };

  const adminPostApiCall = async ({ url, body, formData }) => {
    const token = localStorage.getItem('token');
    setSuccess(false);
    let config;
    if (formData) {
      config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };
    } else {
      config = {
        headers: { Authorization: `Bearer ${token}` },
      };
    }
    try {
      setIsLoading(true);
      const res = await axios.post(`${apiBaseUrl}${url}`, body, config);
      if (res.status === 200) {
        setResult(res.data);
        setSuccess(true);
      }
      setIsLoading(false);
    } catch (error) {
      handleUnauthorizedAccess(error);
      setIsLoading(false);
      setIsError(error.response);
      setResult();
    }
  };

  const adminGetApiCall = async url => {
    const token = localStorage.getItem('token');
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    setSuccess(false);
    try {
      setIsLoading(true);
      const res = await axios.get(`${apiBaseUrl}${url}`, config);
      if (res.status === 200) {
        setResult(res.data);
        setSuccess(true);
      }
      setIsLoading(false);
    } catch (error) {
      handleUnauthorizedAccess(error);
      setIsLoading(false);
      setIsError(error.response);
      setResult({ success: false });
    }
  };

  const dashboardGetApi = () => {};

  const reset = () => {
    setIsError(false);
    setResult();
  };

  function handleUnauthorizedAccess(error) {
    if (error?.response?.status === 401) {
      localStorage.clear();
      console.log(error, '***e');
      navigate('/dashboard');
    }
  }

  return {
    loginApiCall,
    isLoading,
    isError,
    result,
    adminPostApiCall,
    reset,
    adminGetApiCall,
    success,
  };
};
