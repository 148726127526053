import { toast } from 'sonner';

function useErrorHandler() {
  const addOperatorError = error => {
    // console.log(error, '***e');

    switch (error?.status) {
      case 409:
        toast.error('User already exists. Check Email, Aadhaar, Phone Number.');
        break;
      default:
        toast.error(
          `Some thing went wrong. Error code ${error?.status || 500}`
        );
    }
  };

  return { addOperatorError };
}

export default useErrorHandler;
