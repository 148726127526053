import { useEffect } from 'react';

export function useTheme() {
  useEffect(() => {}, []);

  const applyTheme = mode => {
    if (window.localStorage) {
      const theme = window.localStorage.getItem('theme');
      document.querySelector('html').setAttribute('data-theme', mode || theme);
      if (theme === 'dark') {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    }
  };

  const detectWidndowsTheme = () => {
    if (window.matchMedia) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        localStorage.setItem('theme', 'dark');
      } else {
        localStorage.setItem('theme', 'light');
      }
    }
  };

  const changeTheme = () => {
    const currentTheme = localStorage.getItem('theme');
    if (currentTheme === 'dark') {
      localStorage.setItem('theme', 'light');
      applyTheme();
    } else {
      localStorage.setItem('theme', 'dark');
      applyTheme();
    }
  };

  return { applyTheme, detectWidndowsTheme, changeTheme };
}
