import React, { useEffect, useState } from 'react';
import { useApiCall } from '../../hooks/useAPICall';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { FaEye } from 'react-icons/fa';
import ViewComplaintData from './ViewComplaintData';

function ComplaintTable() {
  const { adminGetApiCall, isLoading, result, reset } = useApiCall();

  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState();
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    if (rowData?.id) {
      document.getElementById('my_modal_1')?.showModal();
    } else {
      adminGetApiCall('/api/operator/getAllComplaints');
    }
  }, [rowData]);

  const coloumnDef = [
    { header: 'id', accessorKey: 'id' },
    { header: 'Title', accessorKey: 'title' },
    { header: 'Description', accessorKey: 'desciption' },
    { header: 'Type', accessorKey: 'type' },
    { header: 'Status', accessorKey: 'status' },
    {
      header: 'Action',
      cell: info => {
        return (
          <div className="flex justify-between px-1">
            <FaEye
              className="cursor-pointer"
              onClick={() => {
                setRowData(info.row.original);
              }}
            />
            <dialog id="my_modal_1" className="modal">
              {rowData?.id && (
                <ViewComplaintData data={rowData} setRowData={setRowData} />
              )}
            </dialog>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    adminGetApiCall('/api/operator/getAllComplaints');
  }, []);

  const table = useReactTable({
    data: data,
    columns: coloumnDef,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(result)) {
      if (filter === 'all') {
        setData(result);
      } else {
        const temp = result.filter(item => item?.status === filter);
        setData(temp);
      }
    }
  }, [result, filter]);

  return (
    <div className="w-full p-2">
      <div className="flex justify-center w-full py-2">
        <div className="flex gap-2">
          <label htmlFor="qty">Choose Open / Closed Complaints</label>
          <select
            name="qty"
            id="qty"
            onChange={e => {
              setFilter(e.target.value);
            }}
          >
            <option value={'all'}>all</option>
            <option value="OPEN">Open</option>
            <option value="CLOSED">Closed</option>
          </select>
        </div>
      </div>

      <div>
        {isLoading ? (
          'Loading...'
        ) : (
          <table className="w-full text-sm border table-auto border-slate-500">
            <thead className="border-b border-slate-500">
              {table.getHeaderGroups().map((headerData, index) => (
                <tr key={index}>
                  {headerData.headers.map((header, index) => (
                    <th key={index} className="resize">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={index}
                  className="dark:even:bg-slate-800 even:bg-slate-200"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td key={index} className="px-1 border-l border-slate-500">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default ComplaintTable;
