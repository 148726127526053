import React from 'react';

function InputField({
  label,
  error,
  name,
  type,
  onChange,
  onBlur,
  value,
  placeholder,
}) {
  return (
    <label className="w-full form-control">
      <div className="">
        <span className="label-text">{label}</span>
      </div>
      <input
        type={type || 'text'}
        placeholder={placeholder || ''}
        className="w-full py-1 input input-bordered"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />

      {error ? (
        <p className="text-xs text-red-500">{error}</p>
      ) : (
        <p className="text-xs">&nbsp;</p>
      )}
    </label>
  );
}

export default InputField;
