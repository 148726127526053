import React, { useEffect, useState } from 'react';
import { jobSchema } from '../../schemas/schemas';
import { useFormik } from 'formik';
import InputField from '../common/FormFields/InputField';
import { useApiCall } from '../../hooks/useAPICall';
import { toast } from 'sonner';

function AddJob({ setRowData, setAdd }) {
  const {
    adminPostApiCall,
    isLoading,
    result,
    success,
    isError,
    reset,
    adminGetApiCall,
  } = useApiCall();

  const [companyData, setCompanyData] = useState([
    { id: '', name: 'Select Company' },
  ]);

  useEffect(() => {
    adminGetApiCall('/api/admin/getAllCompanies?pageNo=0&pageSize=2500');
  }, []);

  console.log(companyData, '***r');

  const initialValues = {
    companyId: '',
    title: '',
    desciption: '',
    dateOfPosting: '',
    status: '',
    area: '',
    type: '',
    noOfOpenings: '',
    yearsOfExperience: '',
    salary: '',
    dutyHours: '',
    jobDurationMonths: '',
    providentFundIncluded: false,
    workingShift: '',
    foodAccomodation: false,
    travelAllowance: false,
    contactNumber: '',
    workType: '',
  };

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: jobSchema,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      adminPostApiCall({ url: '/api/company/createNewJob', body: values });
    },
  });

  useEffect(() => {
    if (success) {
      if (Array.isArray(result)) {
        const temp = result.map(item => {
          return { name: item.name, id: item.id };
        });
        setCompanyData(prev => [...prev, ...temp]);
      } else {
        setRowData();
        setAdd(false);
        resetForm();
        toast.success('Job added successfully.');
      }
    }
  }, [result, success]);

  console.log(errors, '***e');

  return (
    <div className="w-11/12 max-w-5xl border modal-box customScroll">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => {
            setRowData();
            setAdd(false);
            resetForm();
          }}
        >
          ✕
        </button>
      </form>
      <div>
        <p className="text-xl">Add New Job</p>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-2">
            <InputField
              name="title"
              label="Title"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.title && errors.title ? errors.title : false}
              value={values.title}
            />
            <InputField
              name="area"
              label="Area"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.area && errors.area ? errors.area : false}
              value={values.area}
            />
          </div>
          <InputField
            name="desciption"
            label="desciption"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.desciption && errors.desciption
                ? errors.desciption
                : false
            }
            value={values.desciption}
          />
          <div className="grid grid-cols-3 gap-2">
            <div>
              <label htmlFor="companyId" className="block mb-2">
                Company Name
              </label>
              <select
                name="companyId"
                id="companyId"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyId}
                className="w-full p-2 border rounded-lg"
              >
                {companyData.map((item, index) => (
                  <option value={item.id} label={item.name} key={index} />
                ))}
              </select>
              {touched.companyId && errors.companyId ? (
                <div style={{ color: 'red' }}>{errors.companyId}</div>
              ) : null}
            </div>

            <InputField
              name="status"
              label="Status"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.status && errors.status ? errors.status : false}
              value={values.status}
            />

            <InputField
              name="dateOfPosting"
              label="Date of Posting"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.dateOfPosting && errors.dateOfPosting
                  ? errors.dateOfPosting
                  : false
              }
              value={values.dateOfPosting}
              type="date"
            />

            <InputField
              name="type"
              label="Type"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.type && errors.type ? errors.type : false}
              value={values.type}
            />

            <InputField
              name="noOfOpenings"
              label="Number of Openings"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.noOfOpenings && errors.noOfOpenings
                  ? errors.noOfOpenings
                  : false
              }
              value={values.noOfOpenings}
              type="number"
            />

            <InputField
              name="yearsOfExperience"
              label="Years of Experience"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.yearsOfExperience && errors.yearsOfExperience
                  ? errors.yearsOfExperience
                  : false
              }
              value={values.yearsOfExperience}
              type="number"
            />

            <InputField
              name="salary"
              label="Salary"
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.salary && errors.salary ? errors.salary : false}
              value={values.salary}
              type="number"
            />

            <InputField
              name="dutyHours"
              label="Duty Hours"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.dutyHours && errors.dutyHours ? errors.dutyHours : false
              }
              value={values.dutyHours}
              type="number"
            />

            <InputField
              name="jobDurationMonths"
              label="Job Duration (Months)"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.jobDurationMonths && errors.jobDurationMonths
                  ? errors.jobDurationMonths
                  : false
              }
              value={values.jobDurationMonths}
              type="number"
            />

            <InputField
              name="workingShift"
              label="Working Shift"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.workingShift && errors.workingShift
                  ? errors.workingShift
                  : false
              }
              value={values.workingShift}
            />

            <InputField
              name="contactNumber"
              label="Contact Number"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.contactNumber && errors.contactNumber
                  ? errors.contactNumber
                  : false
              }
              value={values.contactNumber}
            />

            <InputField
              name="workType"
              label="Work Type"
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.workType && errors.workType ? errors.workType : false
              }
              value={values.workType}
            />

            <div className="flex items-center gap-1 ">
              <div className="flex items-center gap-2">
                <label htmlFor="providentFundIncluded">
                  Provident Fund Included
                </label>
                <input
                  id="providentFundIncluded"
                  name="providentFundIncluded"
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.providentFundIncluded}
                />
              </div>
              {touched.providentFundIncluded && errors.providentFundIncluded ? (
                <div style={{ color: 'red' }}>
                  {errors.providentFundIncluded}
                </div>
              ) : null}
            </div>

            <div className="flex items-center gap-1 ">
              <div className="flex items-center gap-2">
                <label htmlFor="foodAccomodation">Food and Accommodation</label>
                <input
                  id="foodAccomodation"
                  name="foodAccomodation"
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.foodAccomodation}
                />
              </div>

              {touched.foodAccomodation && errors.foodAccomodation ? (
                <div style={{ color: 'red' }}>{errors.foodAccomodation}</div>
              ) : null}
            </div>

            <div className="flex items-center gap-1 ">
              <div className="flex items-center gap-2">
                <label htmlFor="travelAllowance">Travel Allowance</label>
                <input
                  id="travelAllowance"
                  name="travelAllowance"
                  type="checkbox"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.travelAllowance}
                />
              </div>

              {touched.travelAllowance && errors.travelAllowance ? (
                <div style={{ color: 'red' }}>{errors.travelAllowance}</div>
              ) : null}
            </div>
          </div>

          <div className="flex items-center justify-center pt-6">
            <button className="w-32 text-lg btn btn-primary" type="submit">
              {isLoading ? (
                <span className="loading loading-spinner"></span>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddJob;
