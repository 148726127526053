import React, { useEffect, useState } from 'react';
import { useApiCall } from '../../hooks/useAPICall';

function ViewComplaintData(props) {
  const { data, setRowData } = props;
  const {
    adminGetApiCall,
    isLoading,
    result,
    reset,
    success,
    adminPostApiCall,
  } = useApiCall();
  const [companyData, setCompanyData] = useState();
  const [operatorData, setOperatorData] = useState();

  useEffect(() => {
    adminGetApiCall(`/api/company/getCompanyProfile?id=${data?.complaineeId}`);
    adminGetApiCall(
      `/api/operator/getOperatorProfile?id=${data?.complainerId}`
    );
  }, [data?.id]);

  function handleCloseFunction() {
    let bodyData = new FormData();
    bodyData.append('id', data?.id);
    adminPostApiCall({
      url: '/api/operator/closeComplaint',
      body: bodyData,
      formData: true,
    });
  }

  useEffect(() => {
    if (result?.id) {
      if (result.hasOwnProperty('designation')) {
        setOperatorData(result);
      } else {
        setCompanyData(result);
      }
    }
  }, [result]);

  useEffect(() => {
    if (success && !result) {
      setRowData();
    }
  }, [success]);

  //   console.log(companyData, operatorData, '***dr');

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => setRowData()}
        >
          ✕
        </button>
      </form>
      <div>
        <p className="py-2 text-xl">Complaint</p>
        <div className="flex justify-between w-full gap-2">
          <div className="w-1/3 p-2 border rounded-md">
            <div className="flex justify-center w-full text-lg">
              <p>Complaint Details</p>
            </div>
            <p>ID: {data?.id}</p>
            <p>Title: {data?.title}</p>
            <p>Description: {data?.desciption}</p>
            <p>Type: {data?.type}</p>
          </div>
          <div className="w-1/3 p-2 border rounded-md">
            <div className="flex justify-center w-full text-lg">
              <p>Complainer (Operator) Details</p>
            </div>
            <p>ID: {operatorData?.id}</p>
            <p>Name: {operatorData?.name}</p>
            <p>Designation: {operatorData?.designation || 'NA'}</p>
            <p>Email: {operatorData?.email || 'NA'}</p>
            <p>Phone: {operatorData?.contactNo || 'NA'}</p>
            <p>Aadhaar: {operatorData?.uniqueId || 'NA'}</p>
            <p>
              Address:
              {`${operatorData?.houseNo}, ${operatorData?.streetAddress}, ${operatorData?.area}, ${operatorData?.city}, ${operatorData?.state}, ${operatorData?.pincode}`}
            </p>
          </div>
          <div className="w-1/3 p-2 border rounded-md">
            <div className="flex justify-center w-full text-lg">
              <p>Complaint Details</p>
            </div>
            <p>ID: {companyData?.id}</p>
            <p>Name: {companyData?.name}</p>
            <p>Email: {companyData?.email || 'NA'}</p>
            <p>Phone: {companyData?.contactNo || 'NA'}</p>
            <p>CIN: {companyData?.uniqueId || 'NA'}</p>
            <p>
              Address:
              {`${companyData?.houseNo}, ${companyData?.streetAddress}, ${companyData?.area}, ${companyData?.city}, ${companyData?.state}, ${companyData?.pincode}`}
            </p>
          </div>
        </div>
        <div className="flex justify-end p-2">
          <button
            className="btn btn-primary"
            disabled={data?.status === 'CLOSED'}
            onClick={() => handleCloseFunction()}
          >
            Close Complaint
          </button>
        </div>
      </div>
    </div>
  );
}

export default ViewComplaintData;
