import React, { useEffect } from 'react';
import { useApiCall } from '../../hooks/useAPICall';
import { toast } from 'sonner';

function ViewCrowdFundingData({ data, setRowData, pageNo, qty }) {
  const {
    adminGetApiCall,
    isLoading,
    result,
    reset,
    success,
    adminPostApiCall,
  } = useApiCall();

  function closeHandler() {
    adminPostApiCall({
      url: `/api/admin/closeCrowdFunding?crowdFundingId=${data.id}`,
      body: {},
    });
  }

  useEffect(() => {
    if (success) {
      setRowData();
      adminGetApiCall(
        `/api/operator/getAllCrowdFunding?pageNo=${pageNo}&pageSize=${qty}`
      );
      toast.success(`Crowd funding with ID: ${data.id} successfully closed.`);
    }
  }, [result, success]);

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => setRowData()}
        >
          ✕
        </button>
      </form>
      <div className="w-full">
        <p className="flex gap-2">
          <span className="font-bold">ID:</span> {data.id}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Title :</span> {data.title}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Description :</span> {data.description}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Min Contribution :</span>
          {data.minimumContribution}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Target Amount :</span>
          {data.targetAmount}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Amount Raised :</span>
          {data.amountRaised}
        </p>
        <p className="flex gap-2">
          <span className="font-bold">Status :</span>
          {data.status}
        </p>
      </div>
      <div className="flex justify-center w-full">
        <button
          className="btn btn-error"
          disabled={data.status === 'CLOSED' || isLoading}
          onClick={() => {
            closeHandler();
          }}
        >
          {isLoading ? (
            <span className="loading loading-spinner"></span>
          ) : (
            'Close'
          )}
        </button>
      </div>
    </div>
  );
}

export default ViewCrowdFundingData;
