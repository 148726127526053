import React, { useState, useEffect } from 'react';
import { useApiCall } from '../../hooks/useAPICall';
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';
import { FaEye } from 'react-icons/fa';
import { dateGenerator } from '../../util/CommonFunc';
import ViewJobData from './ViewJobData';
import AddJob from './AddJob';

function Job() {
  const [add, setAdd] = useState(false);
  const [data, setData] = useState([]);
  const [rowData, setRowData] = useState();
  const { adminGetApiCall, isLoading, result, reset, success } = useApiCall();

  const coloumnDef = [
    { header: 'id', accessorKey: 'id' },
    {
      header: 'Title',
      accessorKey: 'title',
    },
    {
      header: 'Description',
      accessorKey: 'description',
    },
    { header: 'Company Name', accessorKey: 'companyName' },
    {
      header: 'Date',
      accessorKey: 'dateOfPosting',
      cell: info => {
        const value = info.cell.getValue();
        return <p>{dateGenerator(value)}</p>;
      },
    },
    { header: 'Area', accessorKey: 'area' },
    { header: 'Type', accessorKey: 'type' },
    { header: 'Openings', accessorKey: 'numberOfOpenings' },

    { header: 'Experience', accessorKey: 'yearsOfExperience' },
    { header: 'Salary', accessorKey: 'salary' },

    { header: 'Work Type', accessorKey: 'workType' },
    {
      header: 'Action',
      cell: info => {
        return (
          <div className="flex justify-between px-1">
            <FaEye
              className="cursor-pointer"
              onClick={() => {
                setRowData(info.row.original);
              }}
            />
            <dialog id="my_modal_1" className="modal">
              {rowData?.id && (
                <ViewJobData data={rowData} setRowData={setRowData} />
              )}
            </dialog>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (add) {
      document.getElementById('my_modal_2').showModal();
    } else {
      adminGetApiCall(`/api/operator/getAllJobs`);
    }
  }, [add]);

  useEffect(() => {
    if (rowData?.id) {
      document.getElementById('my_modal_1')?.showModal();
    }
  }, [rowData]);

  const table = useReactTable({
    data: data,
    columns: coloumnDef,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (Array.isArray(result)) {
      setData(result);
    }
  }, [result]);

  console.log(add, '***a');

  return (
    <div>
      <div className="flex justify-end w-full px-2 py-4">
        <button
          className="btn btn-primary"
          onClick={() => {
            setAdd(true);
          }}
        >
          Add New Job
        </button>
        {add && (
          <dialog id="my_modal_2" className="modal">
            <AddJob setRowData={setRowData} setAdd={setAdd} />
          </dialog>
        )}
      </div>
      <div className="h-[calc(100vh-230px)] overflow-y-auto">
        {isLoading ? (
          'Loading...'
        ) : (
          <table className="w-full text-sm border table-auto border-slate-500">
            <thead className="border-b border-slate-500">
              {table.getHeaderGroups().map((headerData, index) => (
                <tr key={index}>
                  {headerData.headers.map((header, index) => (
                    <th key={index} className="resize">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={index}
                  className="dark:even:bg-slate-800 even:bg-slate-200"
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <td key={index} className="px-1 border-l border-slate-500">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default Job;
