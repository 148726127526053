import React from 'react';
import { Outlet } from 'react-router-dom';
import { useAuthentication } from './useAuthentication';
import LoadingPage from '../components/loaders/LoadingPage';

function ProtectedRoutes() {
  const { authenticated, detecting } = useAuthentication();

  if (authenticated) {
    return <Outlet />;
  } else {
    return <LoadingPage />;
  }
}

export default ProtectedRoutes;
