import React, { useEffect, useState } from 'react';
import InputField from '../common/FormFields/InputField';
import { useFormik } from 'formik';
import { operatorSchema } from '../../schemas/schemas';
import { useApiCall } from '../../hooks/useAPICall';
import useErrorHandler from '../../hooks/useErrorHandler';
import { toast } from 'sonner';

export function OperatorForm() {
  const { adminPostApiCall, isLoading, result, isError, reset } = useApiCall();
  const { addOperatorError } = useErrorHandler();
  const [approve, setApprove] = useState(true);

  // console.log(approve, '***');

  useEffect(() => {
    if (isError?.status) {
      addOperatorError(isError);
    }
  }, [isError]);

  const initialValues = {
    fullName: '',
    email: '',
    father: '',
    designation: '',
    contactNo: '',
    uniqueId: '',
    houseNo: '',
    street: '',
    area: '',
    city: '',
    state: '',
    pinCode: '',
    dob: '',
    password: '',
  };

  const {
    errors,
    touched,
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: operatorSchema,
    onSubmit: values => {
      adminPostApiCall({
        url: '/auth/signup/operator',
        body: {
          name: values.fullName,
          email: values.email,
          sonOf: values.father,
          designation: values.designation,
          password: values.password,
          contactNo: values.contactNo,
          uniqueId: values.uniqueId,
          houseNo: values.houseNo,
          streetAddress: values.street,
          area: values.area,
          city: values.city,
          state: values.state,
          pincode: values.pinCode,
          dateOfBirth: values.dob,
          selectValidity: '3',
        },
      });
    },
  });

  useEffect(() => {
    if (result?.userId) {
      toast.success(`Operator Created with id ${result?.userId}`);

      if (approve) {
        adminPostApiCall({
          url: `/api/admin/approveMembership?id=${result?.userId}&req=YES`,
          body: null,
        });
      } else {
        reset();
        resetForm();
      }
    }
    if (typeof result === 'string') {
      toast.success(result);
      reset();
      resetForm();
    }
  }, [result, approve]);

  return (
    <div className="w-11/12 max-w-5xl border modal-box">
      <form method="dialog">
        <button
          className="absolute btn btn-sm btn-circle btn-ghost right-2 top-2"
          onClick={() => {
            resetForm();
          }}
        >
          ✕
        </button>
      </form>
      <h3 className="text-xl font-bold">Add New Operator</h3>
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="flex items-center justify-center gap-3">
          <InputField
            name={'fullName'}
            label={'Full Name'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.fullName && touched.fullName ? errors.fullName : false
            }
            value={values.fullName}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'email'}
            label={'Email Id'}
            type={'email'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.email && touched.email ? errors.email : false}
            value={values.email}
            placeholder={undefined}
          />

          <InputField
            name={'father'}
            label={'Father Name'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.father && touched.father ? errors.father : false}
            value={values.father}
            type={undefined}
            placeholder={undefined}
          />
        </div>

        <div className="flex items-center justify-center gap-3">
          <InputField
            name={'designation'}
            label={'Designation'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.designation && touched.designation
                ? errors.designation
                : false
            }
            value={values.designation}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'contactNo'}
            label={'Phone Number'}
            type={'number'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.contactNo && touched.contactNo ? errors.contactNo : false
            }
            value={values.contactNo}
            placeholder={undefined}
          />

          <InputField
            name={'uniqueId'}
            label={'Aadhaar Number'}
            type={'number'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.uniqueId && touched.uniqueId ? errors.uniqueId : false
            }
            value={values.uniqueId}
            placeholder={undefined}
          />
        </div>

        <div className="flex items-center justify-center gap-3">
          <InputField
            name={'houseNo'}
            label={'House No'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.houseNo && touched.houseNo ? errors.houseNo : false}
            value={values.houseNo}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'street'}
            label={'Street Address'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.street && touched.street ? errors.street : false}
            value={values.street}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'area'}
            label={'Area'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.area && touched.area ? errors.area : false}
            value={values.area}
            type={undefined}
            placeholder={undefined}
          />
        </div>

        <div className="flex items-center justify-center gap-3">
          <InputField
            name={'city'}
            label={'City'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.city && touched.city ? errors.city : false}
            value={values.city}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'state'}
            label={'State'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.state && touched.state ? errors.state : false}
            value={values.state}
            type={undefined}
            placeholder={undefined}
          />

          <InputField
            name={'pinCode'}
            label={'Pin Code'}
            onChange={handleChange}
            onBlur={handleBlur}
            type={'number'}
            error={errors.pinCode && touched.pinCode ? errors.pinCode : false}
            value={values.pinCode}
            placeholder={undefined}
          />
        </div>

        <div className="flex items-center justify-center gap-3">
          <InputField
            name={'dob'}
            label={'Date of Birth'}
            type={'date'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.dob && touched.dob ? errors.dob : false}
            value={values.dob}
            placeholder={undefined}
          />

          <InputField
            name={'password'}
            label={'Password'}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.password && touched.password ? errors.password : false
            }
            value={values.password}
            type={undefined}
            placeholder={undefined}
          />

          <label className="cursor-pointer label">
            <p className="px-3 label-text">Operator Verified</p>
            <input
              type="checkbox"
              className="checkbox checkbox-primary"
              checked={approve}
              onChange={() => setApprove(prev => !prev)}
            />
          </label>

          <button className="w-32 text-lg btn btn-primary" type="submit">
            {isLoading ? (
              <span className="loading loading-spinner"></span>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
