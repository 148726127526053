import React from 'react';
import LoginForm from '../components/login/LoginForm';
import { useAuthentication } from '../auth/useAuthentication';
import { MdOutlineDashboard } from 'react-icons/md';
import { IoMdLogOut } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import useLogout from '../auth/useLougout';
import LoadingPage from '../components/loaders/LoadingPage';

function WelcomePage({ username }) {
  const navigate = useNavigate();
  const { logout } = useLogout();
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-4">
      <p>Welcome {username} </p>
      <button
        className="btn btn-primary"
        onClick={() => {
          navigate('/dashboard');
        }}
      >
        <MdOutlineDashboard className="text-xl" /> Dashboard
      </button>
      <button
        className="btn btn-error"
        onClick={() => {
          logout();
          window.location.reload();
        }}
      >
        <IoMdLogOut className="text-xl" /> Logout
      </button>
    </div>
  );
}

function LandingPage() {
  const { detecting, authenticated, username } = useAuthentication();
  if (detecting) {
    return <LoadingPage />;
  } else {
    if (authenticated) {
      return <WelcomePage username={username} />;
    } else {
      return <LoginForm />;
    }
  }
}

export default LandingPage;
