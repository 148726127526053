import React from 'react';

function LoadingPage() {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen gap-10">
      <p className="text-2xl">Loading ...</p>
      <div className="w-24 h-fit loader"></div>
    </div>
  );
}

export default LoadingPage;
